<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Lessons
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select v-model="query.grade" placeholder="Grade" class="mr10" :clearable="true">
          <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="query.term" placeholder="Term" class="mr10" :clearable="true">
          <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-input v-model="query.name" placeholder="Lesson Name" class="handle-input mr10" @keyup.enter="handleSearch"
          :clearable="true"></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Create a Lesson
        </el-button>
      </div>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @sort-change="changeSort" :row-style="tableRowStyle" :default-sort="{ prop: 'CreatedTime', order: 'ascending' }">
        <el-table-column prop="displayOrder" label="Display Order"
          :width="this.$widthRatio * 94 + 'px'"></el-table-column>
        <el-table-column prop="name" label="Name" sortable="true" :sort-orders="['ascending', 'descending', null]"
          :sort-by="Name"></el-table-column>
        <el-table-column v-if="false" prop="lessonType" label="Lesson Type"
          :width="this.$widthRatio * 160 + 'px'"></el-table-column>
        <el-table-column v-if="false" prop="knowledgePoint" label="Knowledge Point"
          :width="this.$widthRatio * 160 + 'px'"></el-table-column>
        <el-table-column v-if="false" prop="scope" label="Scope" :width="this.$widthRatio * 160 + 'px'"></el-table-column>
        <el-table-column prop="grade" label="Grade" :width="this.$widthRatio * 80 + 'px'" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="Grade"></el-table-column>
        <el-table-column prop="term" label="Term" :width="this.$widthRatio * 70 + 'px'"></el-table-column>
        <el-table-column prop="status" label="Status" :width="this.$widthRatio * 80 + 'px'"></el-table-column>
        <el-table-column prop="updatedTime" sortable="true" :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime" label="Updated Time" :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"></el-table-column>
        <el-table-column label="Operation" :width="this.$widthRatio * 140 + 'px'" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button type="text" class="red" @click="handleDelete(scope.$index, scope.row)">
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
          :page-size="query.pageSize" :total="itemTotal" :page-count="pageTotal"
          @current-change="handlePageChange"></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" width="60%">
        <el-form ref="mainForm" :model="form" :rules="rules" :label-width="this.$widthRatio * 140 + 'px'">
          <el-form-item prop="name" label="Lesson Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="courseId" label="Course">
            <el-select v-model="form.courseId" placeholder="Please select...">
              <el-option v-for="item in options.courseList" :key="item.courseId" :label="item.name"
                :value="item.courseId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="displayOrder" label="Display Order">
            <el-select v-model="form.displayOrder" placeholder="Please select...">
              <el-option v-for="item in options.displayOrderList" :key="item.key" :label="item.value"
                :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="grade" label="Grade">
            <el-select v-model="form.grade" placeholder="Please select...">
              <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="term" label="Term">
            <el-select v-model="form.term" placeholder="Please select...">
              <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="lessonType" label="Lesson Type">
            <el-select v-model="form.lessonType" placeholder="Please select...">
              <el-option label="Junior Course" value="Junior_Course"></el-option>
              <el-option label="HSC Course (Advanced)" value="HSC_Course_Advanced"></el-option>
              <el-option label="HSC Course (Extension 1)" value="HSC_Course_Extension_1"></el-option>
              <el-option label="HSC Course (Extension 2)" value="HSC_Course_Extension_2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="knowledgePoint" label="Knowledge Point">
            <el-input v-model="form.knowledgePoint"></el-input>
          </el-form-item>
          <el-form-item prop="scope" label="Scope">
            <el-select v-model="form.scope" placeholder="Please select...">
              <el-option v-for="item in options.scopeList" :key="item.name" :label="item.title"
                :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="replayLink" label="Replay Link(Hot)">
            <el-input v-model="form.replayLinkForHot"></el-input>
          </el-form-item>
          <el-form-item prop="replayLink" label="Replay Link(Mild)">
            <el-input v-model="form.replayLink"></el-input>
          </el-form-item>
          <el-form-item prop="homeworkLink" label="Homework Link">
            <el-input v-model="form.homeworkLink"></el-input>
          </el-form-item>
          <el-form-item prop="answerLink" label="Solution Link">
            <el-input v-model="form.answerLink"></el-input>
          </el-form-item>
          <el-form-item label="Foundation Link">
            <el-input v-model="form.linkForExtraFoundationExercise" @change="
              handleExtraExerciseLinkChange($event, 'linkForExtraFoundationExercise')
              "></el-input>
            <el-input type="number" v-model="form.pagesForExtraFoundationExercise"></el-input>
          </el-form-item>
          <el-form-item label="Development Link">
            <el-input v-model="form.linkForExtraDevelopmentExercise" @change="
              handleExtraExerciseLinkChange($event, 'linkForExtraDevelopmentExercise')
              "></el-input>
            <el-input type="number" v-model="form.pagesForExtraDevelopmentExercise"></el-input>
          </el-form-item>
          <el-form-item label="Enrichment Link">
            <el-input v-model="form.linkForExtraEnrichmentExercise" @change="
              handleExtraExerciseLinkChange($event, 'linkForExtraEnrichmentExercise')
              "></el-input>
            <el-input type="number" v-model="form.pagesForExtraEnrichmentExercise"></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input type="textarea" rows="5" v-model="form.description"></el-input>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Normal" value="Normal"></el-option>
              <el-option label="Draft" value="Draft"></el-option>
              <el-option label="Deleted" value="Deleted"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveEdit">Save</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";

export default {
  name: "lessonList",
  data() {
    return {
      controllerUrl: "/lesson",
      query: {
        lessonId: null,
        name: null,
        grade: null,
        term: null,
        orderBy: "Generic",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
        termList: [],
        scopeList: [],
        courseList: [],
        lessonTypeList: [],
        displayOrderList: [],
      },
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      rules: {
        name: [
          {
            required: true,
            message: "Please input lesson Name",
            trigger: "blur",
          },
        ],
        courseId: [
          {
            required: true,
            message: "Please select a course",
            trigger: "change",
          },
        ],
        grade: [
          {
            required: true,
            message: "Please select lesson Grade",
            trigger: "change",
          },
        ],
        lessonType: [
          {
            required: true,
            message: "Please select lesson type",
            trigger: "change",
          },
        ],
        term: [
          {
            required: true,
            message: "Please select lesson Term",
            trigger: "change",
          },
        ],
        scope: [
          {
            required: true,
            message: "Please select lesson Scope",
            trigger: "change",
          },
        ],
        replayLink: [
          {
            required: false,
            message: "Please input lesson's replay link address",
            trigger: "change",
          },
        ],
        homeworkLink: [
          {
            required: false,
            message: "Please input lesson's homework link address",
            trigger: "change",
          },
        ],
        answerLink: [
          {
            required: false,
            message: "Please input lesson's answer link address",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select lesson status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;
    this.options.scopeList = this.$appSetting.globalConfig.scopeList;
    this.options.lessonTypeList = this.$appSetting.globalConfig.lessonTypeList;
    for (let i = 0; i < 14; i++) {
      this.options.displayOrderList.push({ key: i + 1, value: i + 1 });
    }

    this.loadAllCourses();
    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    loadAllCourses() {
      getData("/course", {
        courseId: null,
        name: null,
        title: null,
        orderBy: "Grade",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 9999999,
      }).then((res) => {
        if (res.result && res.code === "200") {
          this.options.courseList = res.result.list;
        }
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.lessonId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
              //this.loadData();
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => { });
    },
    handleEdit(index, row) {
      console.log(row);
      if (row.linkForExtraFoundationExercise && !row.pagesForExtraFoundationExercise) {
        row.pagesForExtraFoundationExercise = 2;
      }
      if (row.linkForExtraDevelopmentExercise && !row.pagesForExtraDevelopmentExercise) {
        row.pagesForExtraDevelopmentExercise = 2;
      }
      if (row.linkForExtraEnrichmentExercise && !row.pagesForExtraEnrichmentExercise) {
        row.pagesForExtraEnrichmentExercise = 2;
      }
      this.dialogTitle = "Edit";
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    handleCreate() {
      this.form = { grade: 6, term: 1, status: "Normal" };
      this.dialogTitle = "New";
      this.editVisible = true;
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.form.lessonId && this.form.lessonId.length === 22) {
            putData(this.controllerUrl, this.form.lessonId, this.form).then((res) => {
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Update successfully");
              } else {
                this.$message.error("Update failed, error message: " + res.message);
              }
            });
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error("Create failed, error message: " + res.message);
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    tableRowStyle: function ({ row }) {
      let lessonTypeData = this.options.lessonTypeList.find(
        (e) => e.value === row.lessonTypeValue
      );
      if (!lessonTypeData) return "";
      if (row.lessonTypeValue > 1) {
        return `background-color:${lessonTypeData.branchColor}`;
      }
    },
    handleExtraExerciseLinkChange: function (val, field) {
      console.log("handleExtraExerciseLinkChange", arguments);
      let fieldVal = this.form[field] || "0";
      if (val && parseInt(fieldVal) <= 0) {
        this.form[field] = 2;
      }
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 1.17rem;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
